.layout {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-image: url(https://nimi-1256139749.cos.ap-guangzhou.myqcloud.com/typt_public/images/web/login/body.png);
    background-size: 100% 100%;
    width: 100%;
}

.layout-center {
    position: relative;
    width: 70%;
}

.content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.title-img {
    position: absolute;
    width: 600px;
    height: 68px;
    left: 280px;
    margin: auto 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
}

.title-img img {
    width: 80px;
    height: 80px;
    margin-right: 10px;
}

.title-img-words {
    color: #4F5961;
    text-shadow: 6px 6px 3px #cccccc;
}

.head {

    font-weight: 600;
    font-size: 24px;
    color: #4F5961;
    margin-bottom: 1em;
    text-align: center;
}

.head-mobile {
    font-weight: 600;
    font-size: 24px;
    color: #4F5961;
    margin-bottom: 1em;
    text-align: center;
}

.box {
    display: flex;
    width: 464px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 28px;
    height: 554px;
    margin: auto;
    align-items: center;
    justify-content: center;
}

.qrcode {
    width: 154px !important;
}

.external {
    display: flex;
    align-content: center;
    justify-content: center;
}

.code-url {
    height: 170px;
    position: relative;
    z-index: 3;
}

.text-description {
    font-size: 16px;
    font-weight: 300;
    color: #61666B;
    text-align: center;
    margin-bottom: 10px;
}

.back {
    font-weight: 400;
    text-decoration: underline;
    color: #4F5961;
    font-size: 18px;
    text-align: center;
    position: relative;
    z-index: 9;
}

.back:hover {
    cursor: pointer
}

.external img {
    width: 36px;
    height: 36px;
}

.external span {
    color: #ACB4BA;
    font-size: 12px;
}

.sign-in {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 10px;
    font-weight: 300;
}

.center-ner {
    box-shadow: 0px 7px 27px 0px rgba(133, 169, 231, 0.51);
    overflow: hidden;
    flex-direction: column;
    border-radius: 10px;
    background-color: #fff;
    width: 300px;
    height: 390px;
    padding: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.side {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background: linear-gradient(180deg,
    rgba(111, 178, 255, 1),
    rgba(9, 95, 255, 1));
    overflow: hidden;
    z-index: 1;
}

.minimum {
    min-height: 450px;
}

.img {
    width: 100%;
    /* height: 100%; */
}

.clound {
    position: absolute;
    width: 80px;
    z-index: -1;
}

.clound-1 {
    left: 30px;
    top: 30px;
}

.clound-2 {
    right: 30px;
    top: 10px;
    transform: scale(0.8);
}

.clound-3 {
    left: 80px;
    bottom: 20px;
    transform: scale(0.6);
}

.form-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 48px;
}

.form {
    align-self: stretch;
}

.btn {
    background: linear-gradient(-90deg, #219DFD, #2D7BFB);
    border-radius: 2px;
    box-shadow: 0px 7px 27px 0px rgba(50, 129, 255, 0.51);
    border: none;
    border-radius: 2px;
    height: 3em;
    color: #fff;
    transition: 0.3s;
}

.input {
    height: 3em;
    line-height: 3em;
}

.btn:hover {
    background: linear-gradient(90deg,
    rgba(111, 178, 255, 1),
    rgba(9, 95, 255, 1));
    box-shadow: none;
}

@media screen and (max-width: 576px) {


    .form-wrap {
        padding: 18px;
    }

    .content {
        background-color: #fff;
    }

    .box {
        box-shadow: none;
    }
}

@media screen and (max-width: 1800px) {
    .layout-center {
        width: 80% !important;
    }
}

@media screen and (max-width: 1560px) {
    .layout-center {
        width: 90% !important;
    }
}

@media screen and (max-width: 1400px) {
    .layout-center {
        width: 100% !important;
    }
}

@media screen and (max-width: 1280px) {
    .layout-center {
        width: 100% !important;
    }

    .title-img {
        left: 10%;
    }
}

@media screen and (max-width: 1080px) {
    .layout-center {
        width: 100% !important;
    }

    .title-img {
        display: none;
    }

    .box {
        right: 0;
        left: 0;
    }
}