.header {
    padding-right: 15px;
    display: flex;
    align-items: center;
}

.headerSapn {
    cursor: pointer

}

.head-hover:hover {
    background-color: #eeeeee;
}

.list-hover:hover {
    background-color: #E6F7FF;
}

.ant-drawer-body {
    padding: 0;
}

.hideSlow {
    transition: all 0.8s;
    opacity: 0.4;
    display: none;
}